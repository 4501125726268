import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    stores: [],
  },
  getters: {
    _stores(state) {
      return state.stores
    },
  },
  mutations: {
    setStores(state, stores) {
      state.stores = stores
    },
  },
  actions: {
    getStores(context) {
      return axios.get('/store')
        .then(res => {
          context.commit('setStores', res.data.content)
          return res.data
        })
    },
  },
}
