import axios from '@/libs/axios'

export default {
    namespaced: true,
    state: {
        orders: [],
        selectOrderIds: [],
        pagination: null,
        filter: {
            id: '',
            micro:null,
            order_number: '',
            package_number: '',
            order_status_id: [],
            company_ids: [],
            order_product: {
                product: {
                    id: '',
                    barcode: '',
                    name: '',
                },
            },
            page: 1,
            limit: 25,
            orderById: 'id',
            orderBy: 'desc',
        },
    },
    getters: {
        _orders(state) {
            return state.orders
        },
        _pagination(state) {
            return state.pagination
        },
        _filter(state) {
            return state.filter
        },
    },
    mutations: {
        setOrders(state, content) {
            state.orders = content.data
            delete content.data
            state.pagination = content
        },
        delOrder(state, orderId) {
            const indexId = state.orders.findIndex(order => order.id === orderId)
            state.orders.splice(indexId, 1)
        },
        delOrderProduct(state, orderProductId) {
            state.orders.forEach(order => {
                let orderProdcutIndex = order.order_products.findIndex(item => item.id === orderProductId);
                if (orderProdcutIndex > -1) {
                    order.splice(orderProdcutIndex, 1);
                }
            })
        }
    },
    actions: {
        getOrders(context) {
            return axios.post('/order/list', context.state.filter)
                .then(res => {
                    context.commit('setOrders', res.data.content)
                    return res.data
                })
        },
        setPicking(context, orderId) {
            return axios.put(`/order/picking/${orderId}`)
                .then(res => {
                    context.commit('delOrder', orderId)
                    return res.data
                })
        },
        getMPOrders(context, payload) {
            return axios.get(`/merchantplace/getOrders/${payload}`)
                .then(res => {
                    return res.data
                })
        },
        createDraftBasicInvoice(context, payload) {
            return axios.get('/invoce/earchive/createDraftBasicInvoice/' + payload)
                .then(res => {
                    return res.data
                })
        },
        downloadInvoice(context, payload) {
            return axios.get('/invoce/earchive/downloadInvoice/' + payload)
                .then(res => {
                    this.$swal({
                        width: '800px',
                        html: res.content
                    })
                })
        },
        getBasicInvoicePDF(context, payload) {
            return axios.get('/invoce/earchive/getBasicInvoicePDF/' + payload)
                .then(res => {
                    return res.data
                })
        },
        toApproveInvoice(context, payload) {
            return axios.get('/invoce/earchive/toApproveInvoice/' + payload)
                .then(res => {
                    return res.data
                })
        },
        approveInvoices(context, payload) {
            return axios.post('/invoce/earchive/approveInvoices/' + payload.company_uuid, payload)
                .then(res => {
                    return res.data
                })
        },
        deleteOrderProduct(context, orderProductId) {
            axios.delete(`/order/product/${orderProductId}`)
                .then(res => {
                    if (res.data) {
                        //context.commit('delOrderProduct', orderProductId);
                        alert('Silindi Sayfayı Yenile')
                    }
                })
        }
    },
}
