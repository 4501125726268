import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import product from '@/store/product/product'
import trendyol from '@/store/trendyol/trendyol'
import order from '@/store/order/order'
import company from '@/store/company/company'
import store from '@/store/company/store'
import marketplace from '@/store/company/marketplace'
import statistics from '@/store/home/statistics'
import invoice from '@/store/invoice/invoice'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    product,
    trendyol,
    order,
    company,
    store,
    marketplace,
    statistics,
    invoice,

  },
  strict: process.env.DEV,
})
