import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    companies: [],
    userCompanies: [],
  },
  getters: {
    _companies(state) {
      return state.companies
    },
    _userCompanies(state) {
      return state.userCompanies
    },
  },
  mutations: {
    setCompanies(state, companies) {
      state.companies = companies
    },
    addCompany(state, company) {
      state.companies.push(company)
    },
    setUserCompanies(state, companies) {
      state.userCompanies = companies
    },
  },
  actions: {
    getCompanies(context) {
      return axios.get('company')
        .then(res => {
          context.commit('setCompanies', res.data.content)
          return res.data
        })
    },
    getUserCompanies(context, user_uuid) {
      return axios.get('/company/userCompanies/' + user_uuid)
        .then(res => {
          if (res.data.status == 'success') {
            context.commit('setUserCompanies', res.data.content)
          }
          return res.data
        })
    },
  },
}
