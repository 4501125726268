export default [
  {
    path: '/product',
    component: () => import('@/views/product/ProductHome.vue'),
    meta: {},
    children: [
      {
        path: '/',
        name: 'product-product',
        component: () => import('@/views/product/product/ProductHome.vue'),
        meta: {},
      },
      {
        path: 'add',
        name: 'product-add',
        component: () => import('@/views/product/edit/ProductEditHome.vue'),
      },
      {
        path: 'edit/:id',
        name: 'product-edit',
        component: () => import('@/views/product/edit/ProductEditHome.vue'),
      },
      {
        path: 'category',
        name: 'product-category',
        component: () => import('@/views/product/category/CategoryHome.vue'),
        meta: {},
      },
    ],
  },
]
