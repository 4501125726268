import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    invoices: [],
    pagination: null,
    filter: {},
  },
  getters: {
    _invoices(state) {
      return state.invoices
    },
  },
  mutations: {
    setInvoice(state, content) {
      state.invoices = content.data
      delete content.data
      state.pagination = content
    },
  },
  actions: {
    getInvoices(context) {
      return axios.post('/invoice/list', context.state.filter)
        .then(res => {
          context.commit('setInvoice', res.data.content)
          return res.data
        })
    },
  },
}
