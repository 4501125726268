export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/Profile.vue'),
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/company/CompanyHome.vue'),
  },
  {
    path: '/store',
    name: 'store',
    component: () => import('@/views/store/StoreHome.vue'),
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
