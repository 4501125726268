export default [
  {
    path: '/invoice',
    component: () => import('@/views/invoice/InvoiceHome.vue'),
    meta: {},
    children: [
      {
        path: '/',
        name: 'send-invoice',
        component: () => import('@/views/invoice/invoice/Invoices.vue'),
        meta: {},
      },
    ],
  },
]
