import axios from '@/libs/axios'

export default {
    namespaced: true,
    state: {
        vatRates: [{label: '0', value: 0}, {label: '1', value: 1}, {label: '10', value: 10}, {label: '20', value: 20}],
        product: {},
        products: [],
        pagination: null,
        productBrands: [],
        productStatus: [],
        filter: {
            product: {
                id: '',
                name: '',
                barcode: '',
                product_uuid: '',
                weight: '',
                brandIds: [],
                isBundle: 'all',
            },
            category: {
                id: '',
            },
        },
    },
    getters: {
        _vatRates(state) {
            return state.vatRates
        },
        _product(state) {
            return state.product
        },
        _products(state) {
            return state.products
        },
        _filter(state) {
            return state.filter
        },
        _productBrands(state) {
            return state.productBrands
        },
        _pagination(state) {
          return state.pagination;
        },
        _productStatus(state) {
            return state.productStatus
        },
    },
    mutations: {
        setProduct(state, product) {
            state.product = product
        },
        setProductBundles(state, bundles) {
            state.product.bundle_products = bundles
        },
        setProducts(state, products) {
            state.products = products
        },
        setPagination(state, pagination) {
            state.pagination = pagination
        },
        updateProduct(state, product) {
            let index = state.products.findIndex(item => item.product_uuid == product.product_uuid)
            if (index > -1) {
                state.products.splice(index, 1, product)
            }
        },
        setProductBrands(state, brands) {
            state.productBrands = brands
        },
        addProductBrand(state, brand) {
            state.productBrands.push(brand);
        },
        setProductStatus(state, status) {
            state.productStatus = status
        },
    },
    actions: {
        getPage(context) {
            context.commit('setProducts', [])
            return axios.post('/product', context.state.filter)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setProducts', res.data.content.data)
                        delete res.data.content.data
                        context.commit('setPagination', res.data.content)
                    }
                    return res.data
                })
        },
        getProduct(context, uuid) {
            axios.get('/product/' + uuid)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setProduct', res.data.content)
                    }
                })
        },
        saveProduct(context, data) {
            return axios.post('/product/store', data)
                .then(res => {
                    return res.data
                })
        },
        updateProduct(context, data) {
            return axios.put('/product/' + data.product_uuid, data)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('updateProduct', data)
                    }
                    return res.data
                })
        },
        storeProductImages(context, payload) {
            let data = new FormData()
            data.append('product_uuid', payload.product_uuid)
            payload.images.forEach(item => {
                data.append('images[]', item)
            })

            return axios.post('/product/images', data, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(res => {
                    return res.data
                })
        },
        getProductBrands(context) {
            axios.get('product/brand')
                .then(res => {
                    context.commit('setProductBrands', res.data.content)
                })
        },
        getProductStatus(context) {
            axios.get('/product/status')
                .then(res => {
                    context.commit('setProductStatus', res.data.content)
                })
        },
        storeProductBrand(context, data) {
            return axios.post('/product/brand', data)
                .then(res => {
                    context.commit('addProductBrand', res.data);
                    return res.data
                })
        }
    },
}
