import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    marketplaces: [],
  },
  getters: {
    _marketplaces(state) {
      return state.marketplaces
    },
  },
  mutations: {
    setMarketplaces(state, marketplaces) {
      state.marketplaces = marketplaces
    },
  },
  actions: {
    getMarketplaces(context) {
      return axios.get('marketplace')
        .then(res => {
          context.commit('setMarketplaces', res.data.content)
          return res.data
        })
    },
  },
}
