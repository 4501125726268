export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            action: 'show',
            resource: 'User',
            pageTitle: 'Home',
            breadcrumb: [
                {
                    text: 'Home',
                    active: true,
                },
            ],
        },
    },
]
