export default [
  {
    path: '/order',
    component: () => import('@/views/order/OrderHome.vue'),
    meta: {},
    children: [
      {
        path: '/',
        name: 'order-order',
        component: () => import('@/views/order/order/OrderHome.vue'),
        meta: {},
      },
    ],
  },
]
