export default [
  {
    path: '/trendyol',
    component: () => import('@/views/trendyol/TrendyolHome'),
    meta: {},
    children: [
      {
        path: '/',
        name: 'trendyol-product',
        component: () => import('@/views/trendyol/product/TrendyolProductHome'),
        meta: {},
      },
      {
        path: 'category',
        name: 'trendyol-category',
        component: () => import('@/views/trendyol/product/TrendyolProductHome'),
        meta: {},
      },
    ],
  },
]
